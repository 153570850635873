import {Swiper, Pagination, Navigation, Scrollbar, Autoplay, Parallax} from "swiper";

Swiper.use([Pagination, Navigation, Scrollbar, Autoplay, Parallax]);

$(document).on('turbolinks:load', function() {

  var mySwiper = new Swiper('.before-after-slides', {
    slidesPerView: 1,
    spaceBetween: 10,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },

      // when window width is >= 640px
      640: {
        slidesPerView: 2,
        spaceBetween: 40
      },
      // when window width is >= 640px
      1080: {
        slidesPerView: 3,
        spaceBetween: 40
      }
    },
    effect: 'fade',
    loop: false,
    parallax: true,
    autoplay: {
      delay: 550,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      renderBullet: function (index, className) {
        return '<span class="page"></span>';
      }
    },
  });

});
